<template>
    <div class="customer-transfer-container">
        <div class="customer-transfer-top">
            <van-nav-bar title="客户转移"/>
        </div>
        <div class="customer-transfer-from">
            <van-cell-group>
                <van-cell title="转出部门" :value="info.fromDept||'请选择转出部门'" icon="my iconfont icon-bumen" is-link
                          @click="picker.fromDept=true"/>
                <van-cell title="转出职员" :value="info.fromUser||'请选择转出职员'" icon="my iconfont icon-lizhi" is-link
                          @click="pickerFromUser"/>
            </van-cell-group>
        </div>
        <div class="customer-transfer-to">
            <van-divider dashed>
                转入至
            </van-divider>
            <van-cell-group>
                <van-cell title="转入部门" :value="info.toDept||'请选择转入部门'" icon="my iconfont icon-bumen" is-link
                          @click="picker.toDept=true"/>
                <van-cell title="转入职员" :value="info.toUser||'请选择转入职员'" icon="my iconfont icon-zaizhi" is-link
                          @click="pickerToUser"/>
            </van-cell-group>
        </div>
        <div class="customer-transfer-btn">
            <van-button type="info" size="large" icon="my iconfont icon-zhuanyi" @click="transfer">开始转移</van-button>
        </div>
        <div class="customer-transfer-popup">
            <!-- 部门 -->
            <van-popup v-model="picker.fromDept" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="部门"
                        :columns="fromDeptList"
                        @confirm="onChangeFromDept"
                        @cancel="picker.fromDept=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 销售员 -->
            <van-popup v-model="picker.fromUser" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="职员"
                        :columns="offUserList"
                        @confirm="onChangeFromUser"
                        @cancel="picker.fromUser=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 部门 -->
            <van-popup v-model="picker.toDept" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="部门"
                        :columns="toDeptList"
                        @confirm="onChangeToDept"
                        @cancel="picker.toDept=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 销售员 -->
            <van-popup v-model="picker.toUser" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="职员"
                        :columns="onUserList"
                        @confirm="onChangeToUser"
                        @cancel="picker.toUser=false"
                        :show-toolbar="true"
                />
            </van-popup>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'customer-transfer',
        props: ['node'],
        data() {
            return {
                info: {
                    fromDeptId: 0,
                    fromDept: '',
                    fromUserId: 0,
                    fromUser: '',
                    toDeptId: 0,
                    toDept: '',
                    toUserId: 0,
                    toUser: '',
                },
                fromDeptList: [],
                fromUserList: [],
                toDeptList: [],
                toUserList: [],
                userListCache: [],
                picker: {
                    fromDept: false,
                    fromUser: false,
                    toDept: false,
                    toUser: false,
                }
            }
        },
        mounted: function () {
            this.init();
        },
        computed: {
            offUserList: function () {
                return this.fromUserList.filter(function (item) {
                    return item.status == 0;
                });
            },
            onUserList: function () {
                return this.toUserList.filter(function (item) {
                    return item.status == 1;
                });
            }
        },
        methods: {
            init() {
                this.loadDept();
            },
            transfer() {
                let that = this;
                if (that.info.fromDeptId == 0) {
                    return that.$toast.fail('请选择转出部门');
                }
                if (that.info.fromUserId == 0) {
                    return that.$toast.fail('请选择转出职员');
                }
                if (that.info.toDeptId == 0) {
                    return that.$toast.fail('请选择转入部门');
                }
                if (that.info.toUserId == 0) {
                    return that.$toast.fail('请选择转入职员');
                }
                if (that.info.fromUserId == that.info.toUserId) {
                    return that.$toast.fail('转出和转入职员不能相同');
                }
                that.$api.company.transfer(that.info).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.$dialog.alert({
                            title: '操作成功',
                            message: res.msg
                        });
                        if (that.node) {
                            that.$emit('onClose', that.node);
                        }
                    } else {
                        that.$toast.fail(res.msg);
                    }
                })
            },
            loadDept() {
                let that = this;
                that.$api.user.loadDept().then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.fromDeptList = that.toDeptList = res.data;
                    }
                })
            },
            onChangeFromDept(value) {
                if (this.info.fromDeptId !== value.index) {
                    this.info.fromUserId = 0;
                    this.info.fromUser = '';
                }
                this.picker.fromDept = false;
                this.info.fromDeptId = value.index;
                this.info.fromDept = value.text;
            },
            onChangeToDept(value) {
                if (this.info.toDeptId !== value.index) {
                    this.info.toUserId = 0;
                    this.info.toUser = '';
                }
                this.picker.toDept = false;
                this.info.toDeptId = value.index;
                this.info.toDept = value.text;
            },
            onChangeFromUser(value) {
                this.picker.fromUser = false;
                this.info.fromUserId = value.index;
                this.info.fromUser = value.text;
            },
            onChangeToUser(value) {
                this.picker.toUser = false;
                this.info.toUserId = value.index;
                this.info.toUser = value.text;
            },
            pickerFromUser() {
                if (this.info.fromDeptId == 0)
                    return this.$toast.fail('请选择转出部门');
                let that = this;
                let cache = [];
                if (this.userListCache.length > 0) {
                    this.userListCache.forEach(function (v) {
                        if (v.deptId == that.info.fromDeptId) {
                            cache = v.list;
                        }
                    })
                }
                if (cache.length == 0) {
                    that.$api.user.loadUserByDept(that.info.fromDeptId).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.userListCache.push({deptId: that.info.fromDeptId, list: res.data});
                            that.fromUserList = res.data;
                            that.picker.fromUser = true;
                        } else {
                            that.$toast(res.msg);
                        }
                    })
                } else {
                    this.fromUserList = cache;
                    this.picker.fromUser = true;
                }
            },
            pickerToUser() {
                if (this.info.toDeptId == 0)
                    return this.$toast.fail('请选择转入部门');
                let that = this;
                let cache = [];
                if (this.userListCache.length > 0) {
                    this.userListCache.forEach(function (v) {
                        if (v.deptId == that.info.toDeptId) {
                            cache = v.list;
                        }
                    })
                }
                if (cache.length == 0) {
                    that.$api.user.loadUserByDept(that.info.toDeptId).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.userListCache.push({deptId: that.info.toDeptId, list: res.data});
                            that.toUserList = res.data;
                            that.picker.toUser = true;
                        } else {
                            that.$toast(res.msg);
                        }
                    })
                } else {
                    this.toUserList = cache;
                    this.picker.toUser = true;
                }
            }
        }
    }
</script>

<style scoped>
    .customer-transfer-btn {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
</style>
